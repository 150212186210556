<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl lg:w-full w-5/6 lg:mx-0 mx-auto">

      <h2 class="text-2xl font-semibold py-4 border-b-2 border-red-600">
        Economy
      </h2>
      <img
        src="../../assets/img/tools_economy_banner.jpg"
        alt="Legislation Banner"
        class="w-full lg:h-96 h-80 mt-4"
      >
      <br>
      <br>
      <div>
        Take a macro view of the world's performance.
      </div>
        <br>
        <img
          src="../../assets/img/tools_economy.png"
          alt="Expatriate Practice Banner"
          class="w-full mx-auto"
        >
        <br>
        <div>
          Economic Indicators Worldwide by Country :
          <ul class="px-10 p-4 list-disc">
            <li>GDP size</li>
            <li>GDP growth</li>
            <li>Inflation</li>
            <li>Unemployment</li>
            <li>Investment</li>
            <li>etc.</li>
          </ul>
        </div>
      <br>
      <br>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
